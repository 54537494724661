export function parseTimestampedFile(s) {
  let matches = s?.match(/(?<date>\d{4}-\d{2}-\d{2})-(?<name>[\w-]+)/)
  if (!matches) {
    return {
      name: "untitled",
      date: "unknown",
    }
  }

  return {
    name: matches.groups.name.replace(/-/g, " "),
    // Adding a fake time here to make sure TZ data is captured or we tend to be
    // off by one.
    date: `${matches.groups.date}T12:00:00-05:00`,
  }
}
