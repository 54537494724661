/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"

/**
 * A stylized Link with an arrow to the right that animates on hover.
 */
export const ArrowLink = ({ href, children }) => (
  <Link
    to={href}
    sx={{
      color: "textDim",
      textDecoration: "none",
      borderColor: "transparent",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "::after": {
        content: '"→"',
        color: "textDim",
        marginLeft: 1,
        transition: "all 0.2s",
      },
      ":hover": {
        "::after": {
          color: "primary",
          marginLeft: 2,
        },
        color: "text",
        borderColor: "transparent",
      },
    }}
  >
    {children}
  </Link>
)
