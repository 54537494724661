/** @jsx jsx */
import { Link, graphql } from "gatsby"
import { jsx } from "theme-ui"
import { ArrowLink } from "../components/ArrowLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import dates from "../util/date"
import * as fs from "../util/fs"

const ListItem = ({ title, date, href }) => (
  <li
    sx={{
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
      mb: 1,
    }}
  >
    <Link
      to={href}
      activeClassName="active"
      sx={{
        color: "text",
        textDecoration: "none",
        borderBottom: "2px solid",
        borderColor: "transparent",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        transition: "all 0.2s",
        ":hover": {
          // backgroundColor: "linkHighlight",
          borderColor: "primary",
        },
      }}
    >
      {title}
    </Link>
    <time
      dateTime={date}
      sx={{
        color: "textDim",
        pl: 4,
        whiteSpace: "nowrap",
      }}
    >
      {dates.format(date)}
    </time>
  </li>
)

const Section = ({ title, href, children }) => (
  <section sx={{ mb: 4 }}>
    <h2 sx={{ fontWeight: 500, fontSize: 3, mb: 3 }}>{title}</h2>
    <ol sx={{ listStyle: "none", p: 0, my: 0 }}>{children}</ol>
    <ArrowLink href={href}>more</ArrowLink>
  </section>
)

const HomePage = ({ data: { allMdx, allYoutubeVideo, allFile } }) => (
  <Layout>
    <SEO title="Home" />
    <Section title={"Writing"} href={"/posts"}>
      {allMdx.nodes.map(post => (
        <ListItem
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          href={post.fields.slug}
          key={post.fields.slug}
        />
      ))}
    </Section>
    <Section title={"Music"} href={"/music"}>
      {allYoutubeVideo.edges.map(v => {
        return (
          <ListItem
            title={v.node.title}
            date={v.node.publishedAt}
            href={v.node.url}
            key={v.node.url}
          />
        )
      })}
    </Section>
    <Section title={"Art"} href={"/art"}>
      {allFile.edges.map(art => {
        const { date, name } = fs.parseTimestampedFile(art.node.base)
        return (
          <ListItem
            title={name}
            date={date}
            href={"/art"}
            key={art.node.base}
          />
        )
      })}
    </Section>
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query AllContentQuery {
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 5) {
      nodes {
        frontmatter {
          date
          title
        }
        excerpt
        fields {
          slug
        }
      }
    }
    allYoutubeVideo(sort: { fields: publishedAt, order: DESC }, limit: 5) {
      edges {
        node {
          title
          url
          publishedAt
        }
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "art" }
      }
      sort: { fields: name, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
